<template>
  <div class="col-12 g c col-lg-4">
    <br><br>
    <div class="card card-body">
        <img :src="require('@/assets/images/banner.png')" style="width: 50%; margin: 0px auto; padding: 20px" alt="">
        <div class="alert alert-danger text-center g" v-if="error">
            {{ error }}
        </div>
        <div class="alert alert-success text-center g" v-if="success">
            {{ success }}
        </div>
        <div class="col-12" v-if="step == 0">
            <div class="form-group g">
            <h5 for="">رقم الجوال</h5>
            <input type="text"
                class="form-control form-control-lg" placeholder="اكتب رقم الجوال هنا..." v-model="phone">
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="step1()">
                    التأكد من الرقم
                </button>
            </div>
        </div>
        <div class="col-12" v-if="step == 1">
            <div class="form-group g">
            <h5 for="">كود الاستعادة</h5>
            <input type="text"
                class="form-control form-control-lg" placeholder="اكتب كود الاستعادة هنا..." v-model="code">
            </div>
            <div class="form-group g">
            <h5 for="">كلمة المرور الجديدة</h5>
            <input type="text"
                class="form-control form-control-lg" placeholder="اكتب كلمة المرور الجديدة هنا..." v-model="password">
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="step2()">
                    تغيير كلمة المرور
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    created(){

    },
    data(){
        return {
            phone: "",
            step: 0,
            error: "",
            success: "",
            code: "",
            password: ""
        }
    },
    methods: {
        step1(){
            var g = this;
            g.error = null;
            $.post(api + '/user/auth/reset', {
                phone: this.phone
            }).then(function(r){
                if(r.status != 100){
                    g.error = r.response
                }else{
                    g.step = 1
                    g.success = r.response
                }
            }).fail(function(){
                g.error = "حدث مشكلة في الاتصال"
            })
        },
        step2(){
            var g = this;
            g.error = null;
            $.post(api + '/user/auth/reset_confirm', {
                phone: this.phone,
                code: this.code,
                password: this.password
            }).then(function(r){
                if(r.status != 100){
                    g.error = r.response
                }else{
                    g.success = r.response
                    window.location = '/'
                }
            }).fail(function(){
                g.error = "حدث مشكلة في الاتصال"
            })
        },
    }
}
</script>

<style>

</style>